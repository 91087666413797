<template>
  <section class="hero is-fullheight loginbg">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="hero-body">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <div class="has-text-centered mb-2">
                <img
                  src="~@/assets/depot_logo_dark.svg"
                  alt="Display Depot"
                  width="100%"
                />
              </div>
              <div class="ddsublogo mb-5">
                Online Display Inventory Management Portal
              </div>
              <div
                class="box animated"
                v-bind:class="{
                  shake: form.error,
                  intensifies: form.explode,
                  red: form.explode
                }"
                @keyup.enter.exact="onSubmit()"
              >
                <b-field
                  label="Email"
                  :type="form.controll.email.status"
                  :message="form.controll.email.message"
                >
                  <b-input
                    type="email"
                    v-model="form.email"
                    placeholder="Your Email"
                    icon="envelope"
                  >
                  </b-input>
                </b-field>

                <b-field
                  label="Password"
                  :type="form.controll.pass.status"
                  :message="form.controll.pass.message"
                >
                  <b-input
                    type="password"
                    v-model="form.pass"
                    placeholder="*******"
                    password-reveal
                    icon="lock"
                  >
                  </b-input>
                </b-field>

                <b-message v-if="form.responce" :type="form.status">{{
                  form.message
                }}</b-message>

                <div class="field has-text-centered">
                  <button class="button is-primary" @click="onSubmit()">
                    Log in
                  </button>
                </div>
              </div>
              <div class="has-text-centered">
                <img
                  src="~@/assets/dd_logo.svg"
                  alt="Indexer"
                  width="150"
                /><br />
                <small class="has-text-ddlight"
                  >{{ currentYear }} &copy; Display Dynamics | All Rights
                  Reserved.</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "login",
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "Login | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: "Login into your account"
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "Login into your account",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  }, //end meta
  data() {
    return {
      form: {
        email: "",
        pass: "",
        error: false,
        countErrors: 0,
        explode: false,
        submited: false,
        controll: {
          email: {
            message: null,
            status: null
          },
          pass: {
            message: null,
            status: null
          },
          responce: false,
          message: null,
          status: null
        }
      },
      isLoading: false,
      currentYear: new Date().getFullYear()
    };
  },
  beforeMount() {
    document.body.className = document.body.className.replace(
      "has-navbar-fixed-top",
      ""
    );
  },
  mounted() {
    if (localStorage.uemail) {
      this.form.email = localStorage.uemail;
    }
    // this.$buefy.snackbar.open({
    //   message:
    //     "We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.",
    //   indefinite: true,
    //   onAction: () => {
    //     console.log("some action!")
    //   }
    // });
  },
  methods: {
    onSubmit() {
      //this.$store.commit("DO_LOGIN", true);
      //this.$router.push({ path: "/category/all" });
      if (!this.form.submited) {
        this.form.submited = true;
      } else {
        return;
      }

      if (this.passCheck("pass") & this.emailCheck("email")) {
        console.log(this.form.email);
        console.log(this.form.pass);
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("user/login.php", {
            email: this.form.email,
            pass: this.form.pass,
            action: "user"
          })
          .then(response => {
            this.isLoading = false;
            this.form.responce = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.$store.commit("DO_LOGIN", true);
              //this.$store.commit("SET_USER", response.data);
              this.$store.dispatch("SET_USERDATA", response.data);
              this.$router.push({ path: "/category/all" });
              //this.$router.push({ path: this.$store.state.defaultPath });
            } else {
              this.form.status = "is-danger";
              this.hasError();
              this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.controll.email.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.controll.pass.status = "is-danger";
              } else {
                this.form.controll.email.status = "is-danger";
                this.form.controll.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        console.log("error!");
        this.hasError();
        this.form.countErrors++;
        //intensifies
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form[e] === "" || !re.test(this.form[e])) {
        this.form.controll[e].message = "Please enter a valid Email";
        this.form.controll[e].status = "is-danger";
        return false;
      } else {
        this.form.controll[e].message = null;
        this.form.controll[e].status = "is-success";
        return true;
      }
    },
    passCheck(e) {
      if (this.form[e] === "") {
        this.form.controll[e].message = "Please enter a Password";
        this.form.controll[e].status = "is-danger";
        return false;
      } else {
        this.form.controll[e].message = null;
        this.form.controll[e].status = "is-success";
        return true;
      }
    },
    hasError() {
      if (this.form.countErrors > 2) {
        this.hasMultipleErrors();
        this.form.countErrors = 0;
      } else {
        this.form.error = true;
        setTimeout(() => (this.form.error = false), 1500);
      }
      setTimeout(() => (this.form.submited = false), 1500);
    },
    hasMultipleErrors() {
      this.form.explode = true;
      setTimeout(() => (this.form.explode = false), 2000);
    }
  },
  watch: {
    // femail: function(val){
    //   this.email = val;
    //   console.log(this.email);
    // }
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    }
  }
};
</script>
<style scoped>
@import "~@/assets/css/intensifies.css";
.red {
  background: #e40000;
  transition: background 2s;
}
.body.has-navbar-fixed-top,
html.has-navbar-fixed-top {
  padding-top: 0 !important;
}
</style>
